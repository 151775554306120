<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号" prop="id">
                <a-input v-model="queryParam.id" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="商铺名称" prop="shopName">
                <a-input v-model="queryParam.shopName" placeholder="请输入商铺名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入用户手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="是否开票" prop="isInvoice">
                <a-select placeholder="请选择是否开票" style="width: 100%" v-model="queryParam.isInvoice" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="订单状态" prop="orderStatus">
                <a-select placeholder="请选择订单状态" style="width: 100%" v-model="queryParam.orderStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderShopStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="下单开始时间" prop="createBeginTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime" @change = "startTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="下单结束时间" prop="createEndTime">
                <a-date-picker style="width: 100%" v-model="queryParam.createEndTime" @change = "endTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="签约开始时间" prop="signingTimeStart">
                <a-date-picker style="width: 100%" v-model="queryParam.signingTimeStart" @change="signStartTimeChange"  format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="签约结束时间" prop="signingTimeEnd">
                <a-date-picker style="width: 100%" v-model="queryParam.signingTimeEnd" @change="signEndTimeChange"  format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>


            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="" tab="全部订单"/>
        <a-tab-pane v-for="pane in this.customDict.OrderShopStatusEnum" :key="pane.type" :tab="pane.name">
        </a-tab-pane>
      </a-tabs>

      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['order:shop:edit']">
          <a-icon type="edit" />签约
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:shop:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <detail-form
        ref="detailForm"
        @ok="getList"
      />
      <remark-form
        ref="remarkForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        @change="handleSortChange"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="signingTime" slot-scope="text, record">
          {{ parseTime(record.signingTime) }}
        </span>
        <span slot="isInvoice" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isInvoice"/>
        </span>
        <span slot="orderStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.OrderShopStatusEnum" :value="record.orderStatus"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical"/>
          <a @click="$refs.detailForm.handleDetail(record.id)">
            <a-icon type="eye" />详情
          </a>
          <a-divider type="vertical"/>
          <a @click="$refs.remarkForm.handleRemark(record)">
            <a-icon type="eye" />备注
          </a>
          <a-divider type="vertical" v-hasPermi="['order:shop:signing']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.orderStatus===2010" v-hasPermi="['order:shop:signing']">
            <a-icon type="edit" />签约
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageShop,listShop, delShop } from '@/api/order/shop'
import CreateForm from './modules/CreateForm'
import DetailForm from './modules/DetailForm'
import { tableMixin } from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import RemarkForm from "@/views/order/order/modules/RemarkForm";

export default {
  name: 'Shop',
  components: {
    CustomDictTag,
    CreateForm,
    DetailForm,
    RemarkForm,
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      activeKey: null,//被选中的tab
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        shopId: null,
        mobile: null,
        shopName: null,
        orderStatus: null,
        signingTimeStart: null,
        signingTimeEnd: null,
        createBeginTime: null,
        createEndTime: null,
        finalAmount: null,
        payAmount: null,
        signingTime: null,
        isInvoice: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '订单编号',
          dataIndex: 'id',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          scopedSlots: { customRender: 'orderStatus' },
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '用户手机号',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '商铺名称',
          dataIndex: 'shopName',
          align: 'center'
        },
        {
          title: '中标价',
          dataIndex: 'finalAmount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '保证金 ',
          dataIndex: 'promiseAmount',
          align: 'center'
        },
        {
          title: '成交时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '签约时间',
          dataIndex: 'signingTime',
          scopedSlots: { customRender: 'signingTime' },
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: '是否开票',
          dataIndex: 'isInvoice',
          scopedSlots: { customRender: 'isInvoice' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询订单-商铺订单列表 */
    getList () {
      this.loading = true
     pageShop(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    startTimeChange(date, dateString){
      this.queryParam.createBeginTime = dateString
    },
    endTimeChange(date, dateString){
      this.queryParam.createEndTime = dateString
    },
    signStartTimeChange(date, dateString){
      this.queryParam.signingTimeStart = dateString
    },
    signEndTimeChange(date, dateString){
      this.queryParam.signingTimeEnd = dateString
    },
    //切换tab
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.queryParam.orderStatus = index;
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        shopId: undefined,
        shopSnapshot: undefined,
        finalAmount: undefined,
        payAmount: undefined,
        signingTime: undefined,
        isInvoice: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delShop(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('order/shop/export', {
            ...that.queryParam
          }, `订单-商铺订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
